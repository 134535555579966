import React from 'react';
import InterventionForm from '../components/InterventionForm';

const AddIntervention = () => {
    return (
        <div>
            <InterventionForm onInterventionAdded={() => { /* Add any required logic here */ }} />
        </div>
    );
};

export default AddIntervention;
