import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import debounce from 'lodash/debounce';
import './InterventionsPage.css'; // Import your CSS for custom styles

function InterventionsPage() {
    const [interventions, setInterventions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredInterventions, setFilteredInterventions] = useState([]);
    const [userRole, setUserRole] = useState(null); // State to hold the user's role
    const navigate = useNavigate();

    // Fetch data from API
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch interventions data
                const resInterventions = await axios.get('/api/interventions', {
                    headers: { Authorization: localStorage.getItem('token') }
                });
                setInterventions(resInterventions.data);
                setFilteredInterventions(resInterventions.data);

                // Fetch user role from backend
                const resUser = await axios.get('/api/auth/user', {
                    headers: { Authorization: localStorage.getItem('token') }
                });
                setUserRole(resUser.data.role);
            } catch (err) {
                toast.error('Failed to fetch data');
                navigate('/');
            }
        };

        fetchData();
    }, [navigate]);

    // Debounced filter function
    const filterInterventions = useCallback(
        debounce(() => {
            const filtered = interventions.filter(intervention =>
                (intervention.client?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
                (intervention.zone?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
                (intervention.typeReclamation?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
                (intervention.technicien?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
                (intervention.clientPro?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
                (intervention.etat?.toLowerCase() || '').includes(searchQuery.toLowerCase())
            );

            // Sort by date in descending order
            setFilteredInterventions(
                filtered.sort((a, b) => new Date(b.date) - new Date(a.date))
            );
        }, 300), [interventions, searchQuery]
    );

    // Update filtered interventions when searchQuery or interventions change
    useEffect(() => {
        filterInterventions();
    }, [searchQuery, filterInterventions]);

    // Scroll to top function
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Navigate to previous page
    const goBack = () => {
        navigate(-1); // Go back one page in history
    };

    // Navigate to add intervention page
    const handleAddReclamation = () => {
        navigate('/interventions/add');
    };

    return (
        <div className="interventions-page">
            {/* Back to Previous Page Button */}
            <button className="back-to-previous" onClick={goBack}>
                ← Back
            </button>

            <h1 className="page-title">Interventions</h1>
            
            <h2 className="subtitle">Etat de suivi des interventions</h2>
            
            {/* Conditionally render Add Reclamation button */}
            {userRole === 'Admin' && (
                <button className="btn btn-primary add-reclamation-button" onClick={handleAddReclamation}>
                    Add Intervention
                </button>
            )}
            
            {/* Search Bar */}
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search interventions..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="search-input"
                />
            </div>
            
            <table className="interventions-table">
                <thead>
                    <tr>
                        <th>Client</th>
                        <th>Zone</th>
                        <th>Type de Reclamation</th>
                        <th>Technicien</th>
                        <th>Client Pro</th>
                        <th>Etat</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredInterventions.length ? (
                        filteredInterventions.map((intervention) => (
                            <tr key={intervention._id}>
                                <td>{intervention.client}</td>
                                <td>{intervention.zone}</td>
                                <td>{intervention.typeReclamation}</td>
                                <td>{intervention.technicien}</td>
                                <td>{intervention.clientPro}</td>
                                <td>{intervention.etat}</td>
                                <td>{new Date(intervention.date).toLocaleDateString()}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7">No interventions found</td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Back to Top Button */}
            <button className="back-to-top" onClick={scrollToTop}>
                ↑
            </button>

            <ToastContainer />
        </div>
    );
}

export default InterventionsPage;
