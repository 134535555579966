// src/pages/ReclamationsPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ReclamationsPage() {
    const [reclamations, setReclamations] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get('/api/reclamations', {
                    headers: { Authorization: localStorage.getItem('token') }
                });
                setReclamations(res.data);
            } catch (err) {
                toast.error('Failed to fetch reclamations data');
                navigate('/');
            }
        };

        fetchData();
    }, [navigate]);

    return (
        <div className="container">
            <h2>Reclamations</h2>
            <table className="table">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Client Pro</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {reclamations.map((reclamation) => (
                        <tr key={reclamation._id}>
                            <td>{reclamation.title}</td>
                            <td>{reclamation.description}</td>
                            <td>{reclamation.clientPro}</td>
                            <td>{new Date(reclamation.date).toLocaleDateString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <ToastContainer />
        </div>
    );
}

export default ReclamationsPage;
