import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './InterventionForm.css'; // Import the CSS file

function InterventionForm({ onInterventionAdded }) {
    const [intervention, setIntervention] = useState({
        client: '',
        zone: '',
        typeReclamation: '',
        technicien: '',
        clientPro: '',
        etat: '',
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        setIntervention({ ...intervention, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!intervention.client || !intervention.zone || !intervention.typeReclamation || !intervention.technicien || !intervention.etat) {
            toast.error('Please fill in all required fields.');
            return;
        }

        try {
            const res = await axios.post('/api/interventions', intervention, {
                headers: { Authorization: localStorage.getItem('token') }
            });
            toast.success('Intervention added successfully');
            onInterventionAdded(res.data);
            navigate('/interventions');
        } catch (err) {
            toast.error('Failed to add intervention');
        }
    };

    return (
        <div className="form-container">
            <h1>Add Intervention</h1>
            <form onSubmit={handleSubmit} className="intervention-form">
                <label>
                    <span>Client:</span>
                    <input
                        type="text"
                        name="client"
                        value={intervention.client}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    <span>Zone:</span>
                    <input
                        type="text"
                        name="zone"
                        value={intervention.zone}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    <span>Type de Reclamation:</span>
                    <input
                        type="text"
                        name="typeReclamation"
                        value={intervention.typeReclamation}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    <span>Technicien:</span>
                    <input
                        type="text"
                        name="technicien"
                        value={intervention.technicien}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    <span>Client Pro:</span>
                    <select
                        name="clientPro"
                        value={intervention.clientPro}
                        onChange={handleChange}
                    >
                        <option value="">ㅤ</option>
                        <option value="Spacenet">Spacenet</option>
                        <option value="Tunisianet">Tunisianet</option>
                        <option value="Affariyet">Affariyet</option>
                        <option value="tryandbuy">tryandbuy</option>
                        <option value="koktahome">koktahome</option>
                        <option value="Synotec">synotec</option>
                        <option value="Zoom">zoom</option>
                        <option value="Jmb">Jmb</option>
                        <option value="Oxtek">Oxtek</option>
                        <option value="Electrotounes">Electrotounes</option>
                        <option value="Electro-mbh">Electro-mbh</option>
                        <option value="Graiet">Graiet</option>
                        <option value="Promouv">Promouv</option>
                        <option value="Talos">Talos</option>
                    </select>
                </label>
                <label>
                    <span>Etat:</span>
                    <input
                        type="text"
                        name="etat"
                        value={intervention.etat}
                        onChange={handleChange}
                        required
                    />
                </label>
                <button type="submit" className="btn btn-primary">Add Intervention</button>
            </form>
            <ToastContainer />
        </div>
    );
}

export default InterventionForm;
