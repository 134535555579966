// src/App.js
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import RegisterPage from './pages/RegisterPage';
import InterventionsPage from './pages/InterventionsPage'; // Import
import AddIntervention from './pages/AddIntervention';
import ReclamationsPage from './pages/ReclamationsPage'; // Import
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/interventions" element={<InterventionsPage />} /> {/* New Route */}
                <Route path="/interventions/add" element={<AddIntervention />} />
                <Route path="/reclamations" element={<ReclamationsPage />} /> {/* New Route */}
            </Routes>
        </Router>
    );
}

export default App;
