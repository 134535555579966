// src/pages/HomePage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Import axios here
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function HomePage() {
    const [user, setUser] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const res = await axios.get('/api/auth/user', {
                    headers: { 
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
            	console.log(res.data);
                setUser(res.data);
            } catch (err) {
                toast.error('Failed to fetch user data');
                navigate('/');
            }
        };

        fetchUserData();
    }, [navigate]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/');
    };

    return (
        <div className="container mt-5">
            <div className="row mb-4">
                <div className="col-12 text-center">
                    <h2>Welcome, {user.username}</h2>
                    <button className="btn btn-danger mt-3" onClick={handleLogout}>
                        Disconnect
                    </button>
                </div>
            </div>

            <div className="row text-center">
                <div className="col-md-6 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={() => navigate('/interventions')}
                            >
                                View Interventions
                            </button>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 mb-4">
                    <div className="card">
                        
                        <div className="card-body">
                            <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={() => navigate('/reclamations')}
                            >
                                Add Reclamations
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer />
        </div>
    );
}

export default HomePage;
