import React, { useState } from 'react';
import axios from '../axiosConfig'; // Import configured axios instance
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './LoginPage.css'; // Import custom CSS for login page
import 'bootstrap/dist/css/bootstrap.min.css';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post('/api/auth/login', { email, password });
            localStorage.setItem('token', res.data.token);
            toast.success('Login successful');
            navigate('/home'); // Navigate to home page after successful login
        } catch (err) {
            toast.error('Login failed');
        }
    };

    return (
        <div className="login-container d-flex align-items-center justify-content-center min-vh-100" style={{ backgroundColor: '#E5F3F8' }}>
            <div className="login-box p-4 shadow-lg rounded text-center" style={{ backgroundColor: 'white', maxWidth: '400px' }}>
                <img src="logoselect.png" alt="Select Home Equipment" className="img-fluid mb-3" style={{ maxHeight: '50px' }} />
                <h2 className="mb-4">Service après vente</h2>
                <form onSubmit={handleLogin}>
                    <div className="form-group mb-3 text-start">
                        <label htmlFor="email">Email address</label>
                        <input
                            id="email"
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group mb-3 text-start">
                        <label htmlFor="password">Password</label>
                        <input
                            id="password"
                            type="password"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary w-100">Submit</button>
                </form>
                <ToastContainer />
            </div>
        </div>
    );
}

export default LoginPage;
