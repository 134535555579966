import React, { useState } from 'react';
import axios from '../axiosConfig'; // Import configured axios instance
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function RegisterPage() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [revendeurType, setRevendeurType] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            await axios.post('/api/auth/register', { username, email, password, role, revendeurType });
            toast.success('Registration successful');
            navigate('/'); // Navigate to login page after successful registration
        } catch (err) {
            toast.error('Registration failed');
        }
    };

    return (
        <div className="container d-flex align-items-center justify-content-center min-vh-100">
            <div className="col-md-4">
                <h2 className="text-center mb-4">Register</h2>
                <form onSubmit={handleRegister}>
                    <div className="form-group mb-3">
                        <label>Username</label>
                        <input
                            type="text"
                            className="form-control"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label>Email</label>
                        <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label>Password</label>
                        <input
                            type="password"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label>Role</label>
                        <select
                            className="form-control"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            required
                        >
                            <option value="">Select Role</option>
                            <option value="Admin">Admin</option>
                            <option value="Commercial">Commercial</option>
                            <option value="Revendeur">Revendeur</option>
                        </select>
                    </div>
                    {role === 'Revendeur' && (
                        <div className="form-group mb-3">
                            <label>Revendeur Type</label>
                            <input
                                type="text"
                                className="form-control"
                                value={revendeurType}
                                onChange={(e) => setRevendeurType(e.target.value)}
                            />
                        </div>
                    )}
                    <button type="submit" className="btn btn-primary w-100">Register</button>
                </form>
                <ToastContainer />
            </div>
        </div>
    );
}

export default RegisterPage;
